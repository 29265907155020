body,
html {
  /* overflow: auto; */
  -webkit-overflow-scrolling: touch;
  scroll-behavior: auto !important;
}
input,
textarea,
select {
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

tr.endData > td {
  background-color: #e8faf0 !important;
}
.actable > :not(caption) > * > * {
  background-color: transparent !important;
}

.stockLocationRow td {
  background-color: #f0f1f5 !important;
  font-size: 15px;
  padding: 3px 8px;
}

.searchInventory {
  position: fixed;
  height: 65px;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}

tr.itemMainInfo > td {
  border-top: 2px solid #c2c8db;
}

.whiteSpece {
  white-space: pre;
}

.badge-number {
  position: absolute !important;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
  }

  .table th,
  .table td {
    padding: 8px;
  }

  .btn {
    padding: 5px 10px;
    font-size: 12px;
  }

  .form-control {
    font-size: 14px;
  }
}

.actable th,
.actable td {
  white-space: nowrap;
}

.description-cell {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stock-description-cell {
  max-width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-code-cell {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
