.viewDetails td {
  font-weight: bold;
  color: #2f4483;
}

tbody.saleContain {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
}
thead.saleContain,
tbody.saleContain tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

tbody.searchContain {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
thead.searchContain,
tbody.searchContain tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
